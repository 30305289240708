/* stylelint-disable color-no-hex */

$color-theme: (
  neutral: (
    white: #fff,
    light: #eee,
    neutral: #aaa,
    black: #000,
    red: rgb(166, 47, 44),
    blue: #345f90,
    green: #10ac84,
  ),
);

/* define Archive */

.Archive {
  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  li {
    display: list-item;
    text-align: left;
  }

  .Archive__link {
    font-size: 2rem;
    text-align: center;
  }
}

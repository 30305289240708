@import '../../../styles/config/all.scss';

html,
body {
  font-family: futura, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: palette(neutral, light);
  margin: 0;
  padding: 0;
}

.Content {
  padding: $size-m;

  /* 75px is the Header height */
  padding-top: ($size-m * 2) + $size-xl + $size-m + $size-s;
}

.EquipeGames {
  box-sizing: border-box;
}

.Loader {
  font-size: 2rem;
  margin-top: 100px;
  text-align: center;
}

.Side {
  padding: 0 $size-m $size-m;
  cursor: pointer;
  text-align: justify;
}

.Side > .ReadMore:hover,
.Side:hover > h2,
.Side:hover > h3 {
  color: palette(neutral, red);
  transition: all 0.3s;
}

.Side:not(:last-of-type) {
  border-bottom: 4px dotted palette(neutral, neutral);
}

@media screen and (max-width: 768px) {
  .Content {
    padding: 0;
    padding-top: ($size-m * 2) + $size-xl + $size-m + $size-s;
  }
}
